import React, { useEffect, useContext } from 'react';
import Helmet from 'react-helmet';
import { marked } from 'marked';
import ReactHTMLParser from 'react-html-parser';

import { BUTTON_STYLE, BREAKPOINT } from '@latitude/core/utils/constants';
import { HorizontalRule } from '@latitude/horizontal-rule';
import { Link } from '@latitude/link';
import Section from '@latitude/section';
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import SvgInline from '@latitude/svg-inline';

import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import { Strong } from '@/components/Text/Bold';

import Layout from '@/components/layout';
import { ImportantInformation } from '@latitude/important-information';
import { Text } from '@/components/Text';

import PageData from '../../../data/pages/credit-cards/index.json';
import heroImage from './images/hero-banner-image.webp';
import bookingBanner from './images/booking-banner-gemvisa.webp';
import WhyChoose from './_why-choose';
import ExpediaWotifSection from './_expedia-wotif';
import RatesAndFees from './_rates-and-fees';
import HowToApply from './_how-to-apply';
import PromoInterestFree from './_promo-interest-free';
import PromoVisa from './_promo-visa';
import CarouselHeader from '@/components/Carousel/carouselHeader';

import PaymentOptions, {
  PaymentOptionsColumn,
  PaymentOptionIcon,
  PaymentOptionTitle,
  PaymentOptionDescription,
  PaymentOptionImages,
  PaymentOptionButtons,
  PaymentOptionNote,
  PaymentOptionCallout,
  PaymentOptionList,
  PaymentOptionListItem,
  PaymentOptionHeading
} from '@/components/PaymentOptions/PaymentOptions';

import { DigitalWallets } from '@latitude/digital-wallets';
import BrandedCallout from '@latitude/callout/BrandedCallout';

import { PageContext } from '@/context/PageContext';
import PromoBanner from '@/components/lab-components/PromoBanner';
import HeroBanner from '@/components/lab-components/HeroBanner';
import LabRatesAndFees from '@/components/lab-components/LabRatesAndFees';
import GemMicrositeHeader from './_gem-microsite-header';
import FeeChangesCCSection from '../_feeChanges';
import { NotificationBanner } from '@latitude/banner';

const APPLY_URL = 'https://cards.latitudefinancial.com/gem';
const HEADER_ITEMS = [
  {
    title: 'Shop today with more time to pay',
    text:
      'Enjoy 6 months Interest Free on everyday purchases of $250 or more with your Latitude Gem Visa credit card.',
    termsconds: '',
    headerImage: heroImage,
    urlFindOutMore: '',
    urlApplyNow: APPLY_URL
  }
];

const featureSliderItems = [
  {
    icon: 'icon-raised-fist',
    title: 'Pay off early. No worries.',
    text:
      'Want to pay off your Interest Free plan early? Go for it, there are no penalties.'
  },
  {
    icon: 'icon-everyday',
    title: 'The perfect card, everyday.',
    text:
      "The Latitude Gem Visa offers up to 55 days interest free<sup>1</sup>  on everyday purchases, so it's the perfect go-to card for your everyday spending too."
  },
  {
    icon: 'icon-cardless-branded',
    title: 'Ready, set, tap.',
    text:
      'With our instant cardless payment feature, you can start shopping from your phone in minutes.'
  },
  {
    icon: 'icon-stay-on-track',
    title: 'Managing your account made easy.',
    text:
      'The <a href="/mobile-app/">Latitude App</a> makes it easier than ever to track spending and manage repayments.'
  },
  {
    icon: 'icon-pl-2',
    title: 'Booking.com partnership',
    jsx: (
      <p
        css={`
          font-family: Roboto, Open Sans, sans-serif;
          font-size: 16px;
          font-weight: 300;
          line-height: 26px;
          text-align: center;
          color: #42484d;
        `}
      >
        Get 6% off your stay instantly with{' '}
        <a href="/travelpartners">Booking.com</a> every day, all year round via
        the Latitude App<sup>3</sup>.
      </p>
    )
  }
];

function CreditCardsPage(props) {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [
    {
      heading: 'Why choose Latitude Gem Visa?',
      featureCards: featureSliderItems
    }
  ];
  /** - END - */

  return (
    <Layout location={props.location} hasSecNav>
      <main
        className="navigation-spacer"
        css={`
          margin-top: 130px !important;
          background-color: #f8f8f8;
          .microsite-header {
            z-index: 2000 !important;
          }

          div[class^='MicrositeHeader__SecondBar'].microsite-header__nav-wrap {
            margin-top: 8px;
          }
        `}
      >
        <Helmet>
          <link rel="canonical" href="/credit-cards/" />
          <title>Gem Visa Credit Card | Latitude Financial</title>
          <meta name="description" content={PageData.metaDesc} />
        </Helmet>
        <GemMicrositeHeader data={state.secondaryNavData} />
        {!state?.alertData && (
          <NotificationBanner siteName="latitudefinancial-com-au" />
        )}
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <CarouselHeader HEADER_ITEMS={HEADER_ITEMS} />
        )}

        <div
          className="d-none d-lg-block"
          css={`
            position: relative;
            z-index: 10;
            .sticky-navigation--fixed {
              top: 40px !important;
            }
          `}
        >
          <StickyNavigationBranded
            items={[
              {
                anchor: 'why-us',
                label: 'Why us?'
              },
              {
                anchor: 'rates-and-fees',
                label: 'Rates & Fees'
              },
              {
                anchor: 'payment-options',
                label: 'Payment Options'
              },
              {
                anchor: 'digital-wallets',
                label: 'Digital wallets'
              },
              {
                anchor: 'how-to-apply',
                label: 'How to apply'
              },
              {
                anchor: 'eligibility',
                label: 'Eligibility'
              }
            ]}
            offsetElem="[data-sticky-navigation-offset]"
            trackEventData={{
              category: 'cta',
              action: 'quote-link',
              location: 'sticky'
            }}
            trackId="sticky-get-started"
            {...state?.inPageNavData?.[0]}
          />
        </div>

        {/* <FeeChangesCCSection
          heading="Notice of Variation to your Latitude Gem Visa credit card contract"
          body="Effective from 17 September 2024, we are changing the credit card annual percentage rate on purchases from 27.49% p.a. to 27.99% p.a."
          link="https://latitudefs.zendesk.com/hc/en-au/articles/5078660193041-Credit-Card-Pricing-Changes"
        /> */}
        <WhyChoose />

        <HorizontalRule />
        {state?.promoBannerData?.[0] ? (
          <PromoBanner {...state?.promoBannerData[0]} />
        ) : (
          <ExpediaWotifSection id="expedia-wotif-offers" />
        )}

        {state?.ratesAndFeesData?.[0] ? (
          <LabRatesAndFees {...state.ratesAndFeesData[0]} />
        ) : (
          <RatesAndFees />
        )}

        <HowToApply />
        {state?.promoBannerData?.[1] ? (
          <PromoBanner {...state?.promoBannerData[1]} />
        ) : (
          <PromoInterestFree />
        )}
        <PromoVisa />

        <Section
          heading="Payment Options"
          id="payment-options"
          className="section--payment-options"
          css={`
            && {
              @media (min-width: ${BREAKPOINT.LG}) {
                padding-bottom: 72px;
              }
            }
          `}
        >
          <PaymentOptions
            data={[
              {
                id: 'latitude-app',
                title: 'Latitude App',
                titleHtml: 'Latitude App',
                content: (
                  <React.Fragment>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-latitude-app" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>Latitude App</PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Make repayments in just a few taps with our mobile app.
                      </PaymentOptionDescription>
                      <PaymentOptionImages>
                        <Link
                          href="https://itunes.apple.com/app/apple-store/id1373059866?pt=118165058&ct=InfPayOpt0319&mt=8"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-inline"
                          data-trackid="payment-options-itunes-app-link"
                          trackEventData={{
                            label: ''
                          }}
                        >
                          <img
                            src={
                              require('../../../images/badge-app-store.svg')
                                .default
                            }
                            height="48px"
                            alt="app store"
                          />
                        </Link>
                        <Link
                          href="https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp&referrer=utm_source%3DInfPayOpt0319"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-inline"
                          data-trackid="payment-options-googleplay-app-link"
                          trackEventData={{
                            label: ''
                          }}
                        >
                          <img
                            src={
                              require('../../../images/badge-google-play.svg')
                                .default
                            }
                            height="48px"
                            alt="play store"
                          />
                        </Link>
                      </PaymentOptionImages>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Get the app</Strong>
                          <Text fontSize="14px">
                            Simply download the Latitude App from the App Store
                            or Google Play
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Create a Latitude ID</Strong>
                          <Text fontSize="14px">
                            Open the app and create your Latitude ID (this is
                            different to your Online Service Centre login).
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Link your card</Strong>
                          <Text fontSize="14px">
                            Once your credit cards are linked, you’ll be able to
                            make payments via bank transfer with your BSB and
                            Account Number (Australia only).
                          </Text>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionNote>
                        <Text fontSize="14px">
                          <Strong>Note:</Strong> Please be aware that bank
                          transfer payments can take three business days to
                          clear and you can only make one payment to your card
                          account every five days.
                        </Text>
                      </PaymentOptionNote>
                      <PaymentOptionCallout>
                        <Strong>No Fees</Strong>
                        <Text fontSize="14px">
                          We do not charge a fee for payments made via direct
                          bank transfer on the Latitude App.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </React.Fragment>
                )
              },
              {
                id: 'online-service-centre',
                title: 'Latitude Service Centre',
                titleHtml: 'Latitude Service Centre',
                content: (
                  <React.Fragment>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-online" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>
                        Latitude Service Centre
                      </PaymentOptionTitle>
                      <PaymentOptionDescription>
                        One of the quickest &amp; easiest way to make
                        repayments.
                      </PaymentOptionDescription>
                      <PaymentOptionButtons css="justify-content:center;">
                        <Link
                          target="_blank"
                          button="secondary"
                          href="https://servicecentre.latitudefinancial.com.au/login"
                          data-trackid="payment-options-service-center-login-link"
                          css="margin: 0 8px; flex: 1 1 50%;"
                          disableDefaultEventTracking
                        >
                          Login
                        </Link>
                        <Link
                          target="_blank"
                          button="tertiary"
                          href="https://servicecentre.latitudefinancial.com.au/register"
                          data-trackid="payment-options-service-center-register-link"
                          css="margin: 0 8px; flex: 1 1 50%;"
                          disableDefaultEventTracking
                        >
                          Register
                        </Link>
                      </PaymentOptionButtons>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionHeading>
                        To make a payment
                      </PaymentOptionHeading>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Login to the Latitude Service Centre</Strong>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Click ‘Pay My Account’</Strong>
                          <Text fontSize="14px">
                            Then enter your bank details.
                          </Text>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                      <PaymentOptionHeading>Registering</PaymentOptionHeading>
                      <Text fontSize="14px">
                        Registering is easy and only takes a few minutes, just
                        make sure you have your card or 16-digit account number
                        on hand.
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <Text fontSize="14px">
                        <Strong>Note:</Strong> Please be aware that payments can
                        take 3 to 5 business days to clear.
                      </Text>
                      <PaymentOptionCallout>
                        <Strong>No Fees</Strong>
                        <Text fontSize="14px">
                          We do not charge a fee for payments made via the
                          Latitude Service Centre.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </React.Fragment>
                )
              },
              {
                id: 'bpay',
                title: 'BPAY',
                titleHtml:
                  'BPAY<sup style="font-size: 0.75em; top: -0.5em;">&reg;</sup>',
                content: (
                  <React.Fragment>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-bpay" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>
                        BPAY<sup>®</sup>
                      </PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Available 24 hours a day, seven days a week.
                      </PaymentOptionDescription>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionHeading>
                        To make a payment
                      </PaymentOptionHeading>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Quote the Biller code</Strong>
                          <Text fontSize="14px">
                            Latitude Gem Visa Biller Code (154799)
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Your account number</Strong>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>The amount you wish to pay</Strong>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <Text fontSize="14px">
                        <Strong>Note:</Strong> If you’re paying by BPAY on your
                        due date, you must complete the transaction before your
                        bank’s cut off time, otherwise the transaction may be
                        processed on the following day. Some BPAY payments can
                        take up to three days to appear on your account.
                      </Text>
                      <PaymentOptionCallout>
                        <Strong>Fees</Strong>
                        <Text fontSize="14px">
                          A payment handling fee of $1.95 applies for all
                          payments made via BPAY<sup>&reg;</sup> to pay your
                          account.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </React.Fragment>
                )
              },
              {
                id: 'direct-debit',
                title: 'Direct Debit',
                titleHtml: 'Direct Debit',
                content: (
                  <>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-direct-debit" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>Direct Debit</PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Easily make recurring repayments.
                      </PaymentOptionDescription>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn longColumn>
                      <Text>
                        The easiest (and quickest) way to pay off your credit
                        card is with a <strong> monthly direct debit</strong>.
                      </Text>
                      <br />
                      <Text>
                        You can choose whether to pay a set amount, the monthly
                        minimum or the full closing balance, and on your due
                        date each month your payment will automatically happen!
                      </Text>
                      <br />
                      <Text>
                        <Link href="https://latitudefs.zendesk.com/hc/en-au/articles/6027991389329-Direct-Debit-Cards">
                          Click here to find out more about setting up a direct
                          debit.
                        </Link>
                      </Text>
                    </PaymentOptionsColumn>
                  </>
                )
              }
            ]}
            // tabContentMinHeight="385px"
            css={`
              && {
                .payment-options-tabs__content {
                  padding: 32px;
                }
              }
            `}
          />
        </Section>

        <DigitalWallets
          heading="Digital wallets"
          subheading="You can pay any way on your phone or wearable tech device with just a tap."
        />

        <BrandedCallout
          line1={
            <React.Fragment>
              <span>For FAQs, useful information and quick and easy forms</span>
              <br />
            </React.Fragment>
          }
          cta={
            <React.Fragment>
              <p>
                <Link
                  button={BUTTON_STYLE.SECONDARY_INVERSE}
                  href="https://latitudefs.zendesk.com/hc/en-au"
                  trackId="callout-visit-our-help-centre"
                  trackEventData={{ location: "We're here to help" }}
                >
                  Visit our Help Centre
                </Link>
              </p>
            </React.Fragment>
          }
        />

        <ImportantInformation
          data={{
            content: {
              importantInformationSectionOne: [
                'Visa and the Visa brand are registered trademarks of Visa International.',
                '<sup id="note-1">1</sup>To take advantage of up to 55 days interest free on everyday credit card purchases, you need to pay the full closing balance (excluding un-expiring 0% Interest Payment Plans) on each statement of account by the applicable due date.',
                '<sup id="note-2">2</sup>Excludes long-term Interest Free payment plans. Other exclusions, T&Cs, card fee & other charges apply. Limited to new and approved Latitude Gem Visa applicants only. Limited to one offer per applicant. To qualify for $200 credit back, you must be approved and fulfil on the terms of the offer by spending $1,000 or more each month on everyday purchases for 3 consecutive months from the date of approval. The $200 credit will be applied within 60 days of meeting the spend criteria. Offer ends 15 Dec 2024. Latitude may vary, extend or withdraw this offer at any time without notice. Everyday purchases do not include cash advances, cash equivalent transactions, card fees or charges, credits, refunds, reimbursements, Long term Interest Free plan(s), interest and balance transfers into an account. Transactions must be fully processed and any pending transactions at the end of the period will not count towards the spend criteria for that period. The account must be open and not in default of the credit contract when the credit is applied.',
                'Apple, the Apple logo, Apple Pay, Apple Watch, Face ID, iPad, iPhone, Safari, and Touch ID are trademarks of Apple Inc., and App Store is a service mark of Apple Inc., registered in the U.S. and other countries.'
              ],
              importantInformationSectionTwo: [
                'Samsung and Samsung Pay are trademarks or registered trademarks of Samsung Electronics Co., Ltd.',
                'Garmin, the Garmin logo, and the Garmin delta are trademarks of Garmin Ltd. or its subsidiaries and are registered in one or more countries, including the U.S. Garmin Pay is a trademark of Garmin Ltd. or its subsidiaries.',
                'Google Pay, Android, the Google Pay Logo, Google Play and the Google Play logo are trademarks of Google LLC.',
                '&reg; Registered to BPAY Pty Ltd ABN 69 079 137 518'
              ]
            }
          }}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
          {...state?.importantInfoData?.[0]}
        />
      </main>
    </Layout>
  );
}

export default CreditCardsPage;
