import React from 'react';
import { BREAKPOINT, BUTTON_STYLE, COLOR, MARGIN, JUSTIFY_CONTENT } from '@latitude/core/utils/constants';
import { Box, Flex } from '@latitude/box';
import { Link } from '@latitude/link';
import { Promo } from '@latitude/promo';
import { Vertical } from '@latitude/spacing';
import imgPromoVisa from './images/promo-visa.jpg';
import imgInPartnershipWithVisa from './images/in-partnership-with-visa.svg';

const PromoVisa = () => (
  <Box.Section backgroundColor={COLOR.GREY6}>
    <Flex
      justifyContent={JUSTIFY_CONTENT.CENTER}
      css={`
        position: relative;
        z-index: 0;
      `}
    >
      <Promo
        title="Use Visa. Get rewarded."
        description="As a Latitude Gem Visa credit cardholder, you get exclusive discounts, offers and deals just for using your card."
        frameImage={<img src={imgPromoVisa} alt="" />}
        frameAlignment="right"
        frameBackgroundColor="#CDE9FE"
        frameBorderColor="#B7F4FB"
        ctaButtons={
          <Flex
            css={`
              justify-content: center;
              @media (min-width: ${BREAKPOINT.LG}) {
                justify-content: flex-end;
              }
            `}
          >
            <Vertical spacing={MARGIN.M24}>
              <Link 
                button={BUTTON_STYLE.SECONDARY}
                href="/credit-cards/gem-visa/visa-offers/" 
                target="_self">
                View Visa offers
              </Link>
              <img src={imgInPartnershipWithVisa} alt="In partnership with Visa" />
            </Vertical>
          </Flex>
        }
        verticalAlignContent={false}
      />
    </Flex>
  </Box.Section>
);

export default PromoVisa;
